import React, { useState } from 'react'
import Banner from '../components/NewTheme/Ui/Banner'
import Wolflayout from '../components/wolflayout'
import ColorButton from '../components/Ui/ColorButton'
import { StaticImage } from 'gatsby-plugin-image'
import WhiteButton from '../components/Ui/WhiteButton'

class YoutubeVideo {
  constructor (title, videoId) {
    this.title = title;
    this.videoId = videoId;
  }
}

const youtubePlaylist = [
  new YoutubeVideo(
    'Aftermovie 2024',
    's3lMVjBR4Io',
  ),
  new YoutubeVideo(
    'Aftermovie 2023',
    'UrWgZaG40wc',
  ),
  new YoutubeVideo(
    'Aftermovie 2022',
    'RACFL23kJzg',
  ),
  new YoutubeVideo(
    'Aftermovie 2021',
    'Dzaj86A9p_4',
  ),
]

const PhotosVideosPage = ({ data }) => {
  const [selectedSection, setSelectedSection] = useState('photos') // État pour gérer la section visible

  return (
    <Wolflayout>
      <Banner title="Photos & Vidéos" />
      
      <div className="flex justify-center mt-12">
        <div className="flex space-x-6">
          <ColorButton
            className="!px-6"
            isBlue={true}
            text="Photos"
            onClick={() => setSelectedSection('photos')}
          />
          <ColorButton
            className="!px-6"
            isYellow={true}
            text="Vidéos"
            onClick={() => setSelectedSection('videos')}
          />
        </div>
      </div>

      <div className="pt-4 pb-0 mx-24">

        {/* PHOTOS */}
        {selectedSection === 'photos' && 
          <center>
            <div className='main-page-project pt-16'>
              <div className="flex flex-col md:grid md:grid-cols-1 md:grid-rows-1 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] mb-12 md:ml-3 md:mr-3 ml-0.5 mr-0.5 lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025"
                    href="https://lefestif.smugmug.com/Le-Festif-2024/2024-Banque-photos-Médias"
                    target='_blank' 
                    rel="noreferrer noopener">
                  <StaticImage src="../images/photos-videos/festif_photos_album_1.jpg" alt="Photo d'une foule assise devant un spectacle intime." className="w-full h-full object-cover" />
                  <div className="main-page-project-box-inner main-page-project-box-inner-lower absolute bottom-0 left-0 w-full my-auto bg-pink-wolf2025 text-white !text-left text-xl justify-center items-center flex">
                    <h2>Le Festif! 2024</h2>
                    <div className='w-full flex justify-end mr-6'>
                      <WhiteButton 
                        text="Voir l'album" 
                        href="https://lefestif.smugmug.com/Le-Festif-2024/2024-Banque-photos-Médias" 
                        externalLink={true} 
                        className="w-36 text-purple-wolf2025 text-center !font-openSans !my-auto" 
                      />
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className='main-page-project'>
              <div className="flex flex-col md:grid md:grid-cols-3 md:grid-rows-1 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] mb-12 md:ml-3 md:mr-3 ml-0.5 mr-0.5 lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025"
                    href="https://lefestif.smugmug.com/Le-Festif-2023/2023-Banque-photos-Médias"
                    target='_blank' 
                    rel="noreferrer noopener">
                  <StaticImage src="../images/photos-videos/festif_photos_album_2.jpg" alt="Photo d'une foule devant le garage du curé." className="w-full h-full object-cover" />
                  <div className="main-page-project-box-inner main-page-project-box-inner-higher absolute bottom-0 left-0 w-full my-auto bg-yellow-wolf2025 text-white !text-left text-xl py-2 flex flex-col">
                    <h2>Le Festif! 2023</h2>
                    <div className='w-full !mb-9'>
                      <p className="main-page-project-box-inner-more">Voir l'album</p>
                    </div>
                  </div>
                </a>
                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025"
                    href="https://lefestif.smugmug.com/Le-Festif-2022/Banque-photos-Médias"
                    target='_blank' 
                    rel="noreferrer noopener">
                  <StaticImage src="../images/photos-videos/festif_photos_album_3.jpg" alt="Spectateurs devant une scène dans la nature." className="w-full h-full object-cover" />
                  <div className="main-page-project-box-inner main-page-project-box-inner-higher absolute bottom-0 left-0 w-full my-auto bg-pink-wolf2025 text-white text-left text-xl py-2 flex flex-col">
                    <h2>Le Festif! 2022</h2>
                    <div className='w-full'>
                      <p className="main-page-project-box-inner-more">Voir l'album</p>
                    </div>
                  </div>
                </a>
                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025"
                    href="https://lefestif.smugmug.com/Le-Festif-2021/Banque-photo-Medias"
                    target='_blank' 
                    rel="noreferrer noopener">
                  <StaticImage src="../images/photos-videos/festif_photos_album_4.jpg" alt="Gens assis au Quai Bell." className="w-full h-full object-cover" />
                  <div className="main-page-project-box-inner main-page-project-box-inner-higher absolute bottom-0 left-0 w-full my-auto bg-blue-wolf2025 text-white text-left text-xl py-2 flex flex-col">
                    <h2>Le Festif! 2021</h2>
                    <div className='w-full'>
                      <p className="main-page-project-box-inner-more">Voir l'album</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </center>
        }

        {/* VIDEOS */}
        {selectedSection === 'videos' && (
          <center>
            {
              youtubePlaylist.map((video, i) => {
                return (
                  <div className='text-center w-1/2 mt-20' key={i}>
                    <h1 className='font-obviously font-bold text-white text-3xl tracking-wider'>{video.title}</h1>
                    <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} mt-4 relative pt-[56.25%]`}>
                      <iframe
                        title={video.title}
                        className="absolute top-0 left-0 w-full h-full mb-[3rem]"
                        src={'https://www.youtube.com/embed/' + video.videoId}
                        allowFullScreen=""
                        height={'100%'}
                        referrerPolicy="origin-when-cross-origin"
                      ></iframe>
                    </div>
                  </div>
                );
              })
            }
          </center>
        )}
      </div>
    </Wolflayout>
  )
}
export default PhotosVideosPage
